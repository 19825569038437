import React from 'react';
import { Row, Col } from 'antd';
import { SYSCO_CANADA_URLS } from '../constants/appConstants';

const Footer = ({ footerLabelTranslation, language }) => {
  return (
    <Row gutter={16} className="section-6">
      <Col xs={24} sm={24} md={9} lg={9} xl={9}>
        <p className="section-6-box-1" key="footer_sysco_global_text_msg">
          {footerLabelTranslation?.footer_sysco_global_text_msg}
        </p>
      </Col>
      <Col xs={24} sm={24} md={3} lg={3} xl={3}>
        <ul className="section-6-box2-list">
          <p className="section-6-box2-heading" key="footer_about_us">
            {footerLabelTranslation?.footer_about_us}
          </p>
          <li>
            <a href={SYSCO_CANADA_URLS.STORY_URL(language)} key="footer_sysco_story">
              {footerLabelTranslation?.footer_sysco_story}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.ANNUAL_REPORTS_URL} key="footer_sysco_annual_report">
              {footerLabelTranslation?.footer_sysco_annual_report}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.CSR_URL(language)} key="footer_sysco_corporate_social_responsibility">
              {footerLabelTranslation?.footer_sysco_corporate_social_responsibility}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.DIVERSITY_URL(language)} key="footer_diversity_inclusion">
              {footerLabelTranslation?.footer_diversity_inclusion}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.NEWS_ROOM_URL(language)} key="footer_news_room">
              {footerLabelTranslation?.footer_news_room}
            </a>
          </li>
        </ul>
      </Col>
      <Col xs={24} sm={24} md={3} lg={3} xl={3}>
        <ul className="section-6-box2-list">
          <p className="section-6-box2-heading" key="footer_customer_solutions">
            {footerLabelTranslation?.footer_customer_solutions}
          </p>
          <li>
            <a href={SYSCO_CANADA_URLS.PRODUCT_CATEGORIES_URL(language)} key="footer_product_categories">
              {footerLabelTranslation?.footer_product_categories}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.BRAND_FAMILY_URL(language)} key="footer_sysco_brand_family">
              {footerLabelTranslation?.footer_sysco_brand_family}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.BUSINESS_RESOURCES_URL(language)} key="footer_business_resources">
              {footerLabelTranslation?.footer_business_resources}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.SYSCO_TECH_SOLUTIONS_URL(language)} key="footer_sysco_source">
              {footerLabelTranslation?.footer_sysco_source}
            </a>
          </li>
        </ul>
      </Col>
      <Col xs={24} sm={24} md={9} lg={3} xl={3}>
        <ul className="section-6-box2-list">
          <p className="section-6-box2-heading" key="footer_resources">
            {footerLabelTranslation?.footer_resources}
          </p>
          <li>
            <a href={SYSCO_CANADA_URLS.INVESTORS} key="footer_investors">
              {footerLabelTranslation?.footer_investors}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.CAREERS_URL(language)} key="footer_careers">
              {footerLabelTranslation?.footer_careers}
            </a>
          </li>
        </ul>
      </Col>
      <Col xs={24} sm={24} md={4} lg={3} xl={3}>
        <ul className="section-6-box2-list">
          <p className="section-6-box2-heading" key="footer_contact_us">
            {footerLabelTranslation?.footer_contact_us}
          </p>
          <li>
            <a href={`${SYSCO_CANADA_URLS.ORDER_NOW_URL}?lang=${language}`} key="footer_order_now">
              {footerLabelTranslation?.footer_order_now}
            </a>
          </li>
          <li>
            <a href={SYSCO_CANADA_URLS.OUR_LOCATIONS_URL(language)} key="footer_our_locations">
              {footerLabelTranslation?.footer_our_locations}
            </a>
          </li>
          <li className="section-6-box2-heading footer-category-support" key="footer_support">
            {footerLabelTranslation?.footer_support}
          </li>
          <li>
            <a aria-label="Report a Concern" href={SYSCO_CANADA_URLS.REPORT_CONCERN} key="footer_report_a_concern">
              {footerLabelTranslation?.footer_report_a_concern}
            </a>
          </li>
        </ul>
      </Col>
      <Col xs={24} sm={24} md={3} lg={3} xl={3}>
        <ul className="section-6-box2-list social-footer-mobile">
          <li className="social-media-list">
            <a href={SYSCO_CANADA_URLS.FACEBOOK}>
              <img className="social-media" src={require('../assets/Images/fb.png')} />
            </a>
          </li>
          <li className="social-media-list">
            <a href={SYSCO_CANADA_URLS.TWITTER}>
              <img className="social-media" src={require('../assets/Images/twiter.png')} />
            </a>
          </li>
          <li className="social-media-list">
            <a href={SYSCO_CANADA_URLS.LINKEDIN}>
              <img className="social-media" src={require('../assets/Images/linkedin.png')} />
            </a>
          </li>
        </ul>
      </Col>

      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <hr></hr>
        <div className="subfooter">
          <img src={require('../assets/Images/Sysco-Logo-Color1.png')} />
          <p key="footer_copyrights">{footerLabelTranslation?.footer_copyrights}</p>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
