import React from 'react';
import { Row, Col } from 'antd';
import '../assets/css/EmptyCatalogues.css';
import { LANGUAGE, CATALOGUES_LOAD_MESSAGES } from '../constants/appConstants';

class EmptyCatalogues extends React.Component {
  render() {
    const { language } = this.props;
    return (
      <h2 className="empty-catalogues-text">
        {CATALOGUES_LOAD_MESSAGES[Object.values(LANGUAGE).includes(language) ? language : LANGUAGE.EN]}
      </h2>
    );
  }
}

export default EmptyCatalogues;
