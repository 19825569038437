export const LANGUAGE = {
  ENGLISH: 'EN',
  FRENCH: 'FR',
};

export const CUSTOMER_TYPES = {
  SYSCO_ASSOCIATE_HELP: 'associate help',
  CREDIT_TERM: 'credit term',
};

export const CUSTOMER_TYPE_TOGGLE_STYLE = {
  SELECTED: 'order-now-delivery-selected',
  NOT_SELECTED: 'order-now-delivery',
};

export const REWARDS_OPTION = {
  EN: ['Advertisement', 'Sysco Marketing Associate', 'Website', 'Sysco Customer Event (Food Show)', 'Other'],
  FR: ['Publicité', 'Associé marketing Sysco', 'Site Web', 'Événement client Sysco (Food Show)', 'Autres'],
};

export const PROVINCE_LIST_OPTION = [
  'Alberta',
  'British Columbia',
  'Saskatchewan',
  'Manitoba',
  'Ontario',
  'Quebec',
  'New Brunswick',
  'Nova Scotia',
  'Prince Edward Island',
  'Newfoundland and Labrador',
];

export const WORKED_WITH_SYSCO_REP_TOGGLE_STYLING = {
  SELETED: 'SyscoAssociate SyscoAssociate-selected',
  NOT_SELECTED: 'SyscoAssociate',
};

export const EMPTY_STRING = '';
export const LANG_CLASS_NAME = 'lang-selected';
export const INVALID_POSTALCODE_NAME = 'Please insert a valid postal code.';

export const PO_BOX = 'po box';
export const EMAIL_VALIDATION_PATTERN = '^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$';
export const PHONE_NUMBER_MASK = '(...)-...-....';
export const PHONE_NUMBER_COUNTRY_CODE = 'ca';
export const PHONE_NUMBER_PLACE_HOLDER = '(000)-000-0000';
export const TEXT_VALIDATION_PATTERN = '(?!^ +$)^.+$';
export const PHONE_NUMBER_LENGTH = 10;

export const MAX_LENGTH_NAME = 30;
export const MAX_LENGTH_BUSINESS_NAME = 25;
export const MAX_LENGTH_EMAIL = 50;
export const MAX_LENGTH_QUESTION = 1000;
export const MAX_LENGTH_STREET_ADDRESS = 25;
export const MAX_LENGTH_CITY = 15;
export const MAX_LENGTH_POSTAL_CODE = 7;

export const POSTAL_CODE_MASK = '### ###';
export const POSTAL_CODE_PLACEHOLDER = ' ';

export const PURCHASE_OPTIONS = {
  OPTION_1: '1000 $',
  OPTION_2: '1000 - 3000 $',
  OPTION_3: '3000 - 5000 $',
  OPTION_4: '5000 $',
};
export const DELIVERY_WINDOW_AM = 'AM';
export const DELIVERY_WINDOW_PM = 'PM';
export const DELIVERY_WINDOW_NO_PREFERENCE = 'NO PREFERENCE';

// UTM Params
export const UTM_PARAMS = {
  GCLID: 'gclid',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
};

export const TERMS_AND_CONDITIONS = {
  PDF_HEIGHT: '600px',
  PDF_WIDTH: '940px',
};

export const BROWSER_TAB_ON_CLOSE_MESSAGE = 'Are you sure to cancel the onboarding?';

// Intercom Base url
export const INTERCOM_BASE_URL = 'https://widget.intercom.io/widget/';

// Sysco Canada urls
const getBaseUrl = (language = LANGUAGE.ENGLISH) => {
  return language === LANGUAGE.FRENCH ? 'https://www.sysco.ca/fr/' : 'https://www.sysco.ca/';
};

export const SYSCO_CANADA_URLS = {
  STORY_URL: (language) => getBaseUrl(language) + 'about/the-sysco-story.html',
  PURPOSE_URL: (language) => getBaseUrl(language) + 'about/our-purpose.html',
  CSR_URL: (language) => getBaseUrl(language) + 'about/corporate-social-responsibility.html',
  ANNUAL_REPORTS_URL: 'https://investors.sysco.com/annual-reports-and-sec-filings/annual-reports',
  MANAGEMENT_URL: (language) => getBaseUrl(language) + 'about/leadership.html',
  DIVERSITY_URL: (language) => getBaseUrl(language) + 'about/diversity-and-inclusion.html',
  NEWS_ROOM_URL: (language) => getBaseUrl(language) + 'about/news.html',
  CORONAVIRUS_URL: (language) => getBaseUrl(language) + 'about/covid-19.html',
  PRODUCT_CATEGORIES_URL: (language) => getBaseUrl(language) + 'products/categories.html',
  CATALOGUES_URL: (language) => getBaseUrl(language) + 'products/catalogues.html',
  BRAND_FAMILY_URL: (language) => getBaseUrl(language) + 'products/brand-family.html',
  QUALITY_ASSUARANCE_URL: (language) => getBaseUrl(language) + 'products/quality-assurance.html',
  SYSCO_TECH_SOLUTIONS_URL: (language) => getBaseUrl(language) + 'services/solutions/sysco-source.html',
  SYSCO_DELIVERY_URL: (language) => getBaseUrl(language) + 'syscodelivery.html',
  ADVANTAGE_URL: (language) => getBaseUrl(language) + 'services/sysco-advantage.html',
  MARKETING_URL: (language) => getBaseUrl(language) + 'services/menu-and-marketing.html',
  BUSINESS_RESOURCES_URL: (language) => getBaseUrl(language) + 'services/business.html',
  DIFFERENCE_URL: (language) => getBaseUrl(language) + 'services/solutions/difference.html',
  FOODIE_URL: (language) => getBaseUrl(language) + 'services/solutions/foodie.html',
  BUSINESS_BOOSTERS_URL: (language) => getBaseUrl(language) + 'businessboosters.html',
  VIRTUAL_KITCHEN_URL: (language) => getBaseUrl(language) + 'virtualkitchen.html',
  PODCASTS_URL: (language) => getBaseUrl(language) + 'services/podcasts-and-webinars.html',
  FOODIES_UNITE_URL: (language) => getBaseUrl(language) + 'foodie/foodies-unite.html',
  FOODIE_MAGAZINE_URL: (language) => getBaseUrl(language) + 'foodie/magazine.html',
  MERCHANDISE_URL: 'https://syscocanada.corpmerchandise.com/',
  REWARDS_URL: (language) => getBaseUrl(language) + 'rewards.html',
  RESTAURANT_SUPPLIER_URL: (language) => getBaseUrl(language) + 'industry/restaurant-food-supply.html',
  SCHOOL_FOOD_SUPPLIER_URL: (language) => getBaseUrl(language) + 'industry/school-food-supply.html',
  GOVERNMENT_FOOD_SUPPLIER_URL: (language) => getBaseUrl(language) + 'industry/government-food-supply.html',
  TOURISM_FOOD_SUPPLIER_URL: (language) => getBaseUrl(language) + 'industry/tourism-food-supply.html',
  HOSPITALITY_FOOD_SUPPLIER_URL: (language) => getBaseUrl(language) + 'industry/hospitality-food-supply.html',
  WHO_WE_ARE_URL: (language) => getBaseUrl(language) + 'healthcare/about.html',
  WHAT_WE_DO_URL: (language) => getBaseUrl(language) + 'healthcare/what-we-do.html',
  SYNERGY_TECH_SUITE_URL: (language) => getBaseUrl(language) + 'healthcare/synergy-tech-suite.html',
  PRODUCT_FEATURES_RECIPES_URL: (language) => getBaseUrl(language) + 'healthcare/product-features.html',
  HEALTHCARE_URL: (language) => getBaseUrl(language) + 'about/covid-19/resources/healthcare-and-senior-living.html',
  NOURISHING_NEWS_URL: (language) => getBaseUrl(language) + 'healthcare/nourishing-news.html',
  NUTRITION_RESOURCES_URL: (language) => getBaseUrl(language) + 'healthcare/nutrition-resources.html',
  CULTURE_AND_BENEFITS_URL: (language) => getBaseUrl(language) + 'careers/culture-and-benefits.html',
  OUR_DEPARTMENT_URL: (language) => getBaseUrl(language) + 'careers/career-paths.html',
  DELIVERY_URL: (language) => getBaseUrl(language) + 'careers/delivery-truck-driver.html',
  INDUSTRIAL_ATHLETE_URL: (language) => getBaseUrl(language) + 'careers/warehouse-industrial-athlete.html',
  MEAT_AND_SEAFOOD_URL: (language) => getBaseUrl(language) + 'careers/specialty-meat-and-seafood.html',
  CAREERS_URL: (language) => getBaseUrl(language) + 'Careers/Find-Your-Next-Career.html',
  ORDER_NOW_URL: '/ordernow',
  CONTACT_US_URL: (language) => getBaseUrl(language) + 'contact/contact-us.html',
  OUR_LOCATIONS_URL: (language) => getBaseUrl(language) + 'contact/contact-us.html',
  REPORT_CONCERN: 'http://ethicsline.sysco.com/',
  INVESTORS: 'http://investors.sysco.com/',
  FACEBOOK: 'https://www.facebook.com/SyscoCanada/',
  TWITTER: 'https://twitter.com/SyscoCanada',
  LINKEDIN: 'https://www.linkedin.com/company/sysco-canada/',
};

export const SYSCO_BUSINESS_ORDER = 'https://syscosource.ca/pnet/eOrder';
export const SYSCO_SOURCE_REGISTER_URL = 'https://syscosource.ca/user-register/';

export const GOOGLE_ANALYTICS = {
  EVENT: 'Create Customer',
  EVENT_CATEGORY: 'NCOB',
  CUSTOMER_EVENT_CATEGORY: 'Customer',
  CUSTOMER_EVENT: 'Sign Up',
  VALUE: 'Success',
};

export const setAppTitle = (language) => {
  const titles = {
    [LANGUAGE.FRENCH]: 'Sysco | Devenez client!',
    default: 'Sysco | Become a Customer!',
  };

  document.title = titles[language] || titles.default;
};

export const CATALOGUES_LOAD_MESSAGES = {
  FR: "Il n'y a aucun catalogue disponible en ce moment.",
  EN: 'There are no catalogues available at the moment.',
};
