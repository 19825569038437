import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { Layout, Row, Col, Card, Button, Form } from 'antd';
import { useIdleTimer } from 'react-idle-timer';
import { useBeforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadCatalogues,
  getLabelTransaltion,
  createCustomer,
  getSegments,
  createCustomerBusiness,
  createShippingDetails,
  createBillingDetails,
  createCustomerDelivery,
  createCustomerPickUp,
  createCustomerRewards,
} from '../actions';
import {
  WORKED_WITH_SYSCO_REP_TOGGLE_STYLING,
  LANGUAGE,
  EMPTY_STRING,
  EMAIL_VALIDATION_PATTERN,
  SYSCO_BUSINESS_ORDER,
  SYSCO_SOURCE_REGISTER_URL,
  BROWSER_TAB_ON_CLOSE_MESSAGE,
  CUSTOMER_TYPES,
  DELIVERY_WINDOW_NO_PREFERENCE,
  PURCHASE_OPTIONS,
  UTM_PARAMS,
  setAppTitle,
} from '../constants/appConstants';
import Header from '../components/header';
import Footer from '../components/Footer';
import AboutYouForm from './AboutYouForm';
import BusinessForm from './BusinessForm';
import ShippingDetails from './ShippingDetails';
import BillingDetails from './Billing';
import RewardsAndFinish from './RewardsAndFinish';
import Complete from './Complete';
import ShippingType from './ShippingType';
import validateAndAddFields from '../utils/FieldValidation';
import Confirmation from './Confimation';
import Timeout from './Timeout';
import Redirect from './Redirect';
import Call from './Call';
import AppLoader from '../components/AppLoader';

import '../assets/css/Home.css';
import '../assets/css/Order.css';

const TIMEOUT = process.env.REACT_APP_TIMEOUT;
const MODAL_CLOSE_LATENCY = process.env.REACT_APP_MODAL_CLOSE_LATENCY;
const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

function OrderNow(props) {
  const { form, lang } = props;

  const { getFieldDecorator, resetFields, validateFields } = form;

  const [isSyscoRep, setIsSyscoRep] = useState(true);

  const [customerType, setCustomerType] = useState(CUSTOMER_TYPES?.SYSCO_ASSOCIATE_HELP);

  const [hasWorkedWithSyscoRep, setHasWorkedWithSyscoRep] = useState(false);
  const [hasNotWorkedWithSyscoRep, setHasNotWorkedWithSyscoRep] = useState(true);

  const [newCustomerModalVisible, setNewCustomerModalVisible] = useState(false);
  const [onboardingCompletedModalVisible, setOnboardingCompletedModalVisible] = useState(false);
  const [billingHeaderVisible, setBillingHeaderVisible] = useState(false);
  const [shippingTypeModalVisible, setShippingTypeModalVisible] = useState(false);

  const [onboardingBusinessModalVisible, setOnboardingBusinessModalVisible] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState(EMPTY_STRING);
  const [selectedIndustryKey, setSelectedIndustryKey] = useState(EMPTY_STRING);
  const [subSegmentTypes, setSubSegmentTypes] = useState([]);
  const [segmentVisible, setSegmentVisible] = useState(false);
  const [subSegmentVisible, setSubSegmentVisible] = useState(false);
  const [segmentBusinessId, setSegmentBusinessId] = useState(0);
  const [businessLoadingState, setBusinessLoadingState] = useState(false);

  const [onboardingShippingModalVisible, setOnboardingShippingModalVisible] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(EMPTY_STRING);
  const [shippingCity, setShippingCity] = useState(EMPTY_STRING);
  const [shippingPostalCode, setShippingPostalCode] = useState(EMPTY_STRING);
  const [shippingProvince, setShippingProvince] = useState(EMPTY_STRING);
  const [shippingConfirm, setShippingConfirm] = useState(false);
  const [isSameBillingAddress, setIsSameBillingAddress] = useState(false);
  const [shippingLoadingState, setShippingLoadingState] = useState(false);

  const [onboardingBillingModalVisible, setOnboardingBillingModalVisible] = useState(false);
  const [billingAddress, setBillingAddress] = useState(EMPTY_STRING);
  const [billingCity, setBillingCity] = useState(EMPTY_STRING);
  const [billingPostalCode, setBillingPostalCode] = useState(EMPTY_STRING);
  const [billingProvince, setBillingProvince] = useState(EMPTY_STRING);
  const [billingConfirm, setBillingConfirm] = useState(false);
  const [billingLoadingState, setBillingLoadingState] = useState(false);

  const [onboardingRewardsModalVisible, setOnboardingRewardsModalVisible] = useState(false);
  const [agreeToSyscoRewards, setAgreeToSyscoRewards] = useState(false);
  const [agreeToSyscoTerms, setAgreeToSyscoTerms] = useState(false);
  const [rewardsOptions, setRewardsOptions] = useState(EMPTY_STRING);
  const [rewardOptionActionNo, setRewardOptionActionNo] = useState(4);
  const [termsAndConditionsVisible, setTermsAndConditionsVisible] = useState(false);
  const [syscoRewardsTermsAndConditionsVisible, setSyscoRewardsTermsAndConditionsVisible] = useState(false);
  const [rewardsLoadingState, setRewardsLoadingState] = useState(false);

  const [customerUserId, setCustomerUserId] = useState(null);
  const [customerFirstName, setCustomerFirstName] = useState(EMPTY_STRING);
  const [customerLastName, setCustomerLastName] = useState(EMPTY_STRING);
  const [customerBusinessName, setCustomerBusinessName] = useState(EMPTY_STRING);
  const [customerEmail, setCustomerEmail] = useState(EMPTY_STRING);
  const [customerPhone, setCustomerPhone] = useState(EMPTY_STRING);
  const [syscoAssociateName, setSyscoAssociateName] = useState(EMPTY_STRING);
  const [customerLoadingState, setCustomerLoadingState] = useState(false);
  const [salesRepPostalCode, setSalesRepPostalCode] = useState(EMPTY_STRING);
  const [isSalesRepPostalCodeError, setIsSalesRepPostalCodeError] = useState(false);

  const [isDelivery, setIsdDelivery] = useState(false);
  const [isPickUp, setIsPickUp] = useState(false);
  const [shippinTypeLoadingState, setShippinTypeLoadingState] = useState(false);

  const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [timeoutModalVisible, setTimeoutModalVisible] = useState(false);
  const [redirectModalVisible, setRedirectModalVisible] = useState(false);
  const [callModalVisible, setCallModalVisible] = useState(false);

  const [maxDeliveryDays, setmaxDeliveryDays] = useState(2);
  const [purchaseOption, setPurchaseOption] = useState(PURCHASE_OPTIONS?.OPTION_2);

  const [selectedNumberOfDays, setSelectedNumberOfDays] = useState(2);

  const [isMonday, setIsMonday] = useState(true);
  const [isTuesday, setIsTuesday] = useState(false);
  const [isWednesday, setIsWednesday] = useState(true);
  const [isThursday, setIsThursday] = useState(false);
  const [isFriday, setIsFriday] = useState(false);

  const [isAM, setIsAM] = useState(false);
  const [isPM, setIsPM] = useState(false);
  const [isNoPreference, setIsNoPreference] = useState(true);

  const [selectedDeliveryDays, setSelectedDeliveryDays] = useState([true, false, true, false, false]);

  const [deliveryWindow, setDeliveryWindow] = useState(DELIVERY_WINDOW_NO_PREFERENCE);

  const [orderNowLabelTranslation, setOrderNowLabelTranslation] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const [language, setLanguage] = useState(Object.values(LANGUAGE).includes(lang) ? lang : LANGUAGE.ENGLISH);

  const [customerResError, setCustomerResError] = useState(false);
  const [businessResError, setBusinessResError] = useState(false);
  const [shippingResError, setShippingResError] = useState(false);
  const [billingResError, setBillingResError] = useState(false);
  const [deliveryResError, setDeliveryResError] = useState(false);
  const [pickUpResError, setPickupResError] = useState(false);
  const [rewardsResError, setRewardsResError] = useState(false);
  const [opcoResError, setOpcoResError] = useState(false);
  const [customerResIsSuccess, setCustomerResIsSuccess] = useState(false);

  const [isBillingProvinceError, setIsBillingProvinceError] = useState(false);
  const [isBillingPostalCodeError, setIsBillingPostalCodeError] = useState(false);
  const [isBillingStreetAddressError, setIsBillingStreetAddressError] = useState(false);

  const [isRewardsOnboardingForm, setIsRewardsOnboardingForm] = useState(false);

  // UTM Params
  const { search } = useLocation();
  const [gclid, setGclid] = useState(EMPTY_STRING);
  const [utmSource, setUtmSource] = useState(EMPTY_STRING);
  const [utmMedium, setUtmMedium] = useState(EMPTY_STRING);
  const [utmCampaign, setUtmCampaign] = useState(EMPTY_STRING);
  const [utmContent, setUtmContent] = useState(EMPTY_STRING);
  const [utmUrl, setUtmUrl] = useState(EMPTY_STRING);

  const getUtmValue = (utmParams) => {
    return !utmParams ? EMPTY_STRING : utmParams;
  };

  const setUtmQueryUrl = (utmParam, value) => {
    const utmQueryData = {};
    if (value) {
      utmQueryData[`${utmParam}`] = value;
    }
    return utmQueryData;
  };

  useEffect(() => {
    const query = queryString.parse(search);
    setGclid(getUtmValue(query?.gclid));
    setUtmSource(getUtmValue(query?.utm_source));
    setUtmMedium(getUtmValue(query?.utm_medium));
    setUtmCampaign(getUtmValue(query?.utm_campaign));
    setUtmContent(getUtmValue(query?.utm_content));

    const sourceParam = setUtmQueryUrl(UTM_PARAMS?.UTM_SOURCE, query?.utm_source);
    const mediumParam = setUtmQueryUrl(UTM_PARAMS?.UTM_MEDIUM, query?.utm_medium);
    const campaignParam = setUtmQueryUrl(UTM_PARAMS?.UTM_CAMPAIGN, query?.utm_campaign);
    const contentParam = setUtmQueryUrl(UTM_PARAMS?.UTM_CONTENT, query?.utm_content);
    const gclidParam = setUtmQueryUrl(UTM_PARAMS?.GCLID, query?.gclid);

    const utmParamUrl = Object.assign(sourceParam, mediumParam, campaignParam, contentParam, gclidParam);

    if (Object.keys(utmParamUrl).length !== 0) {
      setUtmUrl(`?${String(new URLSearchParams(utmParamUrl))}`);
    }
  }, [search]);

  const {
    data: { opcoId: resOpcoId, opcoName: resOpcoName, opcoAddress: resOpcoAddress, distance: resDistance },
    error: opcoError,
  } = useSelector((state) => state.opcoLocation, resOpcoName);

  const {
    data: { isSuccess: customerIsSuccess, userId: customerResUserId },
    error: customerError,
  } = useSelector((state) => state.customer);

  const { error: businessError } = useSelector((state) => state.customerBusiness);

  const {
    data: { isSuccess: shippingIsSuccess },
    error: shippingError,
    fetching: shippingFetching,
  } = useSelector((state) => state.shippingDetails);

  const { error: billingError } = useSelector((state) => state.billingDetails);

  const { error: deliveryError } = useSelector((state) => state.customerDelivery);

  const { error: pickUpError } = useSelector((state) => state.customerPickUp);

  const {
    data: { isSuccess: rewardsIsSuccess, userId },
    error: rewardsError,
    fetching: rewardsFetching,
  } = useSelector((state) => state.customerRewards);

  useEffect(() => {
    if (opcoError === null || opcoError === EMPTY_STRING) {
      setOpcoResError(false);
    } else {
      setOpcoResError(true);
    }
  }, [opcoError]);

  useEffect(() => {
    if (customerError) {
      setCustomerResError(customerError);
    }
  }, [customerError]);

  useEffect(() => {
    if (customerIsSuccess) {
      setCustomerResIsSuccess(customerIsSuccess);
    }
  }, [customerIsSuccess]);

  useEffect(() => {
    if (businessError) {
      setBusinessResError(businessError);
    }
  }, [businessError]);

  useEffect(() => {
    if (shippingError) {
      setShippingResError(shippingError);
    }
  }, [shippingError]);

  useEffect(() => {
    if (billingError) {
      setBillingResError(billingError);
    }
  }, [billingError]);

  useEffect(() => {
    if (deliveryError) {
      setDeliveryResError(deliveryError);
    }
  }, [deliveryError]);

  useEffect(() => {
    if (pickUpError) {
      setPickupResError(pickUpError);
    }
  }, [pickUpError]);

  useEffect(() => {
    if (rewardsError) {
      setRewardsResError(rewardsError);
    }
  }, [rewardsError]);

  useEffect(() => {
    params.set('lang', language.toLowerCase());
    window.history.replaceState(null, '', `?${params.toString()}`);
    setAppTitle(language);
  }, [language]);

  const dispatch = useDispatch();

  const {
    data: { items: segmentData },
  } = useSelector((state) => state.segments);

  useEffect(() => {
    dispatch(loadCatalogues(language));
    dispatch(getSegments(language));
    dispatch(getLabelTransaltion(language));
  }, [dispatch, language]);

  const {
    data: { labelNames },
    fetching: labelFetching,
  } = useSelector((state) => state.labelNames);

  useEffect(() => {
    const labelKey = labelNames?.reduce((acc, curr) => {
      acc[curr.label] = curr.name;
      return acc;
    }, {});
    setOrderNowLabelTranslation(labelKey);
  }, [language, labelNames]);

  useEffect(() => {
    if (selectedIndustry !== EMPTY_STRING) {
      const segmentNames = segmentData.filter((segment) => segment.name?.trim().toLowerCase() === selectedIndustry);
      setSubSegmentTypes(segmentNames && segmentNames[0]?.segments);
    }
  }, [selectedIndustry]);

  useEffect(() => {
    if (customerResUserId !== EMPTY_STRING) {
      setCustomerUserId(customerResUserId);
    }
  }, [customerResUserId]);

  const navigateToSyscoSource = () => {
    window.open(SYSCO_BUSINESS_ORDER, '_blank');
  };

  const navigateToSyscoSourceRegister = () => {
    window.open(SYSCO_SOURCE_REGISTER_URL, '_blank');
  };

  const phoneNumberValidation = (phone) => {
    if (phone.length === 11 && phone.substring(0, 1) === '1') {
      return true;
    }
    return false;
  };

  const clearOnBoardingFormData = () => {
    setCustomerUserId(EMPTY_STRING);
    setCustomerFirstName(EMPTY_STRING);
    setCustomerLastName(EMPTY_STRING);
    setCustomerEmail(EMPTY_STRING);
    setCustomerPhone(EMPTY_STRING);
    setCustomerBusinessName(EMPTY_STRING);
    setHasWorkedWithSyscoRep(false);
    setHasNotWorkedWithSyscoRep(true);
    setSyscoAssociateName(EMPTY_STRING);
    setCustomerResIsSuccess(false);
    setSalesRepPostalCode(EMPTY_STRING);
    setIsSalesRepPostalCodeError(null);

    setSelectedIndustry(EMPTY_STRING);
    setSelectedIndustryKey(EMPTY_STRING);
    setSegmentBusinessId(0);
    setSegmentVisible(false);
    setSubSegmentVisible(false);

    setShippingAddress(EMPTY_STRING);
    setShippingCity(EMPTY_STRING);
    setShippingPostalCode(EMPTY_STRING);
    setShippingProvince(EMPTY_STRING);
    setShippingConfirm(false);
    setIsSameBillingAddress(false);
    setBillingHeaderVisible(false);
    setOpcoResError(false);

    setBillingAddress(EMPTY_STRING);
    setBillingCity(EMPTY_STRING);
    setBillingPostalCode(EMPTY_STRING);
    setBillingProvince(EMPTY_STRING);
    setBillingConfirm(false);

    setIsMonday(true);
    setIsTuesday(false);
    setIsWednesday(true);
    setIsThursday(false);
    setIsFriday(false);

    setIsAM(false);
    setIsPM(false);
    setIsNoPreference(true);
    setDeliveryWindow(DELIVERY_WINDOW_NO_PREFERENCE);

    setmaxDeliveryDays(2);
    setPurchaseOption(PURCHASE_OPTIONS?.OPTION_2);

    setSelectedDeliveryDays([true, false, true, false, false]);
    setSelectedNumberOfDays(2);

    setAgreeToSyscoRewards(false);
    setAgreeToSyscoTerms(false);
    setRewardsOptions(EMPTY_STRING);
    setRewardOptionActionNo(4);
    setSyscoRewardsTermsAndConditionsVisible(false);
    setTermsAndConditionsVisible(false);
    resetFields();

    setCustomerResError(false);
    setBusinessResError(false);
    setShippingResError(false);
    setBillingResError(false);
    setDeliveryResError(false);
    setPickupResError(false);
    setRewardsResError(false);
    setOpcoResError(false);

    setIsRewardsOnboardingForm(false);

    setCustomerLoadingState(false);
    setBusinessLoadingState(false);
    setShippingLoadingState(false);
    setBillingLoadingState(false);
    setRewardsLoadingState(false);
    setShippinTypeLoadingState(false);

    setIsBillingProvinceError(false);
    setIsBillingPostalCodeError(false);
    setIsBillingStreetAddressError(false);

    form.setFieldsValue({
      RewardsSelVal: undefined,
    });
  };

  const saveOptionalDataOnClose = () => {
    const firstName = validateAndAddFields(customerFirstName, 'firstName', null);
    const lastName = validateAndAddFields(customerLastName, 'lastName', null);
    const businessName = validateAndAddFields(customerBusinessName, 'businessName', null);
    const postalCode = isSyscoRep && validateAndAddFields(salesRepPostalCode, 'postalCode', isSalesRepPostalCodeError);
    const syscoAssociate = hasWorkedWithSyscoRep && validateAndAddFields(syscoAssociateName, 'syscoAssociate', null);
    const optionalDataOnClose = Object.assign(firstName, lastName, businessName, postalCode, syscoAssociate);
    optionalDataOnClose.workedWithSyscoAssociate = hasWorkedWithSyscoRep;
    return optionalDataOnClose;
  };

  const saveDataOnClose = () => {
    const dataOnClose = {
      userId: customerUserId,
      isModelClosed: true,
      lang: language,
    };
    setIsModalClosed(true);

    if (
      newCustomerModalVisible &&
      ((customerEmail && customerEmail.match(EMAIL_VALIDATION_PATTERN)) || customerPhone.length === 10)
    ) {
      dataOnClose.customerOption = customerType;
      if (customerEmail && customerEmail.match(EMAIL_VALIDATION_PATTERN) && customerPhone.length === 10) {
        dataOnClose.email = customerEmail;
        dataOnClose.phone = customerPhone;
      } else if (customerEmail && customerEmail.match(EMAIL_VALIDATION_PATTERN)) {
        dataOnClose.email = customerEmail;
      } else if (customerPhone.length === 10) {
        dataOnClose.phone = customerPhone;
      }
      // Set UTM Params
      dataOnClose.gclid = gclid;
      dataOnClose.utmSource = utmSource;
      dataOnClose.utmMedium = utmMedium;
      dataOnClose.utmCampaign = utmCampaign;
      dataOnClose.utmContent = utmContent;
      const dataObject = saveOptionalDataOnClose();
      Object.assign(dataOnClose, dataObject);
      dispatch(createCustomer(dataOnClose));
    } else if (onboardingBusinessModalVisible) {
      dispatch(createCustomerBusiness(dataOnClose));
    } else if (onboardingShippingModalVisible) {
      dispatch(createShippingDetails(dataOnClose));
    } else if (onboardingBillingModalVisible) {
      dispatch(createBillingDetails(dataOnClose));
    } else if (shippingTypeModalVisible) {
      if (isDelivery) {
        dispatch(createCustomerDelivery(dataOnClose));
      } else if (isPickUp) {
        dispatch(createCustomerPickUp(dataOnClose));
      }
    } else if (onboardingRewardsModalVisible) {
      dispatch(createCustomerRewards(dataOnClose));
    }
  };

  const handleTerminateOnBoarding = _.debounce(() => {
    saveDataOnClose();

    setCloseConfirmationVisible(false);
    setNewCustomerModalVisible(false);
    setOnboardingBusinessModalVisible(false);
    setOnboardingShippingModalVisible(false);
    setOnboardingBillingModalVisible(false);
    setShippingTypeModalVisible(false);
    setOnboardingRewardsModalVisible(false);
    clearOnBoardingFormData();
    setIsSyscoRep(true);
    setCustomerType(CUSTOMER_TYPES?.SYSCO_ASSOCIATE_HELP);
  }, MODAL_CLOSE_LATENCY);

  const handleWorkedWithSyscoRepToggleStyling = (workWithSyscoRep) => {
    if (workWithSyscoRep) {
      return WORKED_WITH_SYSCO_REP_TOGGLE_STYLING.SELETED;
    }
    return WORKED_WITH_SYSCO_REP_TOGGLE_STYLING.NOT_SELECTED;
  };

  const handleOnIdle = () => {
    if (
      newCustomerModalVisible ||
      onboardingBusinessModalVisible ||
      onboardingShippingModalVisible ||
      onboardingBillingModalVisible ||
      shippingTypeModalVisible ||
      onboardingRewardsModalVisible
    ) {
      setTimeoutModalVisible(true);
    }
  };

  useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useBeforeunload(() => {
    if (
      !customerResIsSuccess &&
      (newCustomerModalVisible ||
        onboardingBusinessModalVisible ||
        onboardingShippingModalVisible ||
        onboardingBillingModalVisible ||
        shippingTypeModalVisible ||
        onboardingRewardsModalVisible)
    ) {
      return BROWSER_TAB_ON_CLOSE_MESSAGE;
    }
  });

  const googleAnalytics = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', GOOGLE_ANALYTICS_KEY, {
      page_path: '/customer-portal/order-now',
      cookie_domain: 'none',
    });
  };

  useEffect(() => {
    googleAnalytics();
  }, []);

  return (
    <Layout>
      <Header
        language={language}
        setLanguage={setLanguage}
        setNewCustomerModalVisible={setNewCustomerModalVisible}
        headerLabelTranslation={orderNowLabelTranslation}
        utmUrl={utmUrl}
      />

      <Row className="section-2 section-2-ordernow">
        <Col span={24} className="section-2-wrap">
          <h1 className="h1-home-order">{orderNowLabelTranslation?.order_now_ready_to_order}</h1>
        </Col>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h2 className="order-card-left-heading">{orderNowLabelTranslation?.order_now_order_business_today}</h2>
            <Card bordered={false} className="order-card-left">
              <h4 className="order-card-left-text-1">{orderNowLabelTranslation?.order_now_online_business}</h4>
              <p className="order-card-left-text">
                {orderNowLabelTranslation?.order_now_sysco_source_login_register_msg}
              </p>
              <div className="left-box-wrap">
                <Button
                  type="primary"
                  className="btn-primary-account btn-primary-account_order"
                  onClick={() => navigateToSyscoSource()}
                >
                  {orderNowLabelTranslation?.order_now_sysco_source_login}
                </Button>
                <Button
                  type="primary"
                  className="btn-primary-account btn-primary-account_order"
                  onClick={() => navigateToSyscoSourceRegister()}
                >
                  {orderNowLabelTranslation?.order_now_sysco_source_register}
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h2 className="order-card-left-heading">{orderNowLabelTranslation?.order_now_new_customers}</h2>
            <Card bordered={false} className="account-order-card-right">
              <h4 className="order-card-left-text-1">
                {orderNowLabelTranslation?.order_now_create_business_account_text}
              </h4>
              <p>{orderNowLabelTranslation?.order_now_create_business_account_msg}</p>
              <h4 className="order-card-left-text-1">{orderNowLabelTranslation?.order_now_account_benefits}</h4>
              <Col span={24}>
                <img className="benifits-list-item" src={require('../assets/Images/qa.png')} />{' '}
                {orderNowLabelTranslation?.order_now_quality_assurance}
              </Col>
              <Col span={24}>
                <img className="benifits-list-item" src={require('../assets/Images/cs.png')} />{' '}
                {orderNowLabelTranslation?.order_now_customer_service}
              </Col>
              <Col span={24}>
                <img className="benifits-list-item" src={require('../assets/Images/ont.png')} />{' '}
                {orderNowLabelTranslation?.order_now_ordering_and_inventory}
              </Col>
              <Button
                type="primary"
                className="btn-primary-account btn-primary-account_order"
                onClick={() => setNewCustomerModalVisible(true)}
              >
                {orderNowLabelTranslation?.order_now_create_business_account}
              </Button>
            </Card>
          </Col>
        </Row>
      </Row>

      <Footer footerLabelTranslation={orderNowLabelTranslation} language={language} />
      <AboutYouForm
        aboutYouLabelTranslation={orderNowLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        newCustomerModalVisible={newCustomerModalVisible}
        customerUserId={customerUserId}
        isSyscoRep={isSyscoRep}
        billingHeaderVisible={billingHeaderVisible}
        setNewCustomerModalVisible={setNewCustomerModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        setOnboardingBusinessModalVisible={setOnboardingBusinessModalVisible}
        setCustomerEmail={setCustomerEmail}
        setCustomerPhone={setCustomerPhone}
        setHasWorkedWithSyscoRep={setHasWorkedWithSyscoRep}
        customerPhone={customerPhone}
        customerEmail={customerEmail}
        clearOnBoardingFormData={clearOnBoardingFormData}
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        customerType={customerType}
        phoneNumberValidation={phoneNumberValidation}
        handleWorkedWithSyscoRepToggleStyling={handleWorkedWithSyscoRepToggleStyling}
        hasWorkedWithSyscoRep={hasWorkedWithSyscoRep}
        setCustomerFirstName={setCustomerFirstName}
        setCustomerLastName={setCustomerLastName}
        setCustomerBusinessName={setCustomerBusinessName}
        setSyscoAssociateName={setSyscoAssociateName}
        setHasNotWorkedWithSyscoRep={setHasNotWorkedWithSyscoRep}
        customerFirstName={customerFirstName}
        customerLastName={customerLastName}
        customerBusinessName={customerBusinessName}
        syscoAssociateName={syscoAssociateName}
        hasNotWorkedWithSyscoRep={hasNotWorkedWithSyscoRep}
        customerResError={customerResError}
        setRedirectModalVisible={setRedirectModalVisible}
        setCustomerLoadingState={setCustomerLoadingState}
        customerLoadingState={customerLoadingState}
        getFieldDecorator={getFieldDecorator}
        form={form}
        setCustomerResIsSuccess={setCustomerResIsSuccess}
        validateFields={validateFields}
        isSalesRepPostalCodeError={isSalesRepPostalCodeError}
        setIsSalesRepPostalCodeError={setIsSalesRepPostalCodeError}
        salesRepPostalCode={salesRepPostalCode}
        setSalesRepPostalCode={setSalesRepPostalCode}
        gclid={gclid}
        utmSource={utmSource}
        utmMedium={utmMedium}
        utmCampaign={utmCampaign}
        utmContent={utmContent}
      />
      <BusinessForm
        businessLabelTranslation={orderNowLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        onboardingBusinessModalVisible={onboardingBusinessModalVisible}
        setOnboardingBusinessModalVisible={setOnboardingBusinessModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        isSyscoRep={isSyscoRep}
        billingHeaderVisible={billingHeaderVisible}
        setSelectedIndustryKey={setSelectedIndustryKey}
        setSegmentVisible={setSegmentVisible}
        setSubSegmentVisible={setSubSegmentVisible}
        setSelectedIndustry={setSelectedIndustry}
        setNewCustomerModalVisible={setNewCustomerModalVisible}
        segmentBusinessId={segmentBusinessId}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        clearOnBoardingFormData={clearOnBoardingFormData}
        selectedIndustryKey={selectedIndustryKey}
        segmentVisible={segmentVisible}
        subSegmentVisible={subSegmentVisible}
        subSegmentTypes={subSegmentTypes}
        setSegmentBusinessId={setSegmentBusinessId}
        selectedIndustry={selectedIndustry}
        setSubSegmentTypes={setSubSegmentTypes}
        businessResError={businessResError}
        setBusinessLoadingState={setBusinessLoadingState}
        businessLoadingState={businessLoadingState}
        form={form}
        getFieldDecorator={getFieldDecorator}
      />
      <ShippingDetails
        shippingLabelTranslation={orderNowLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        setShippingProvince={setShippingProvince}
        shippingProvince={shippingProvince}
        shippingConfirm={shippingConfirm}
        shippingPostalCode={shippingPostalCode}
        shippingCity={shippingCity}
        shippingAddress={shippingAddress}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        isSameBillingAddress={isSameBillingAddress}
        setIsPickUp={setIsPickUp}
        setIsdDelivery={setIsdDelivery}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        setBillingHeaderVisible={setBillingHeaderVisible}
        setRewardOptionActionNo={setRewardOptionActionNo}
        setOnboardingBillingModalVisible={setOnboardingBillingModalVisible}
        setOnboardingBusinessModalVisible={setOnboardingBusinessModalVisible}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        onboardingShippingModalVisible={onboardingShippingModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        setShippingAddress={setShippingAddress}
        setShippingCity={setShippingCity}
        setShippingPostalCode={setShippingPostalCode}
        setIsSameBillingAddress={setIsSameBillingAddress}
        setShippingConfirm={setShippingConfirm}
        billingHeaderVisible={billingHeaderVisible}
        shippingResError={shippingResError}
        opcoResError={opcoResError}
        setShippingLoadingState={setShippingLoadingState}
        shippingLoadingState={shippingLoadingState}
        setOpcoResError={setOpcoResError}
        setShippingResError={setShippingResError}
        shippingFetching={shippingFetching}
        shippingIsSuccess={shippingIsSuccess}
        form={form}
        getFieldDecorator={getFieldDecorator}
      />
      <BillingDetails
        billingLabelTranslation={orderNowLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        onboardingBillingModalVisible={onboardingBillingModalVisible}
        setOnboardingBillingModalVisible={setOnboardingBillingModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        billingHeaderVisible={billingHeaderVisible}
        billingAddress={billingAddress}
        setBillingAddress={setBillingAddress}
        billingCity={billingCity}
        setBillingCity={setBillingCity}
        setBillingPostalCode={setBillingPostalCode}
        billingPostalCode={billingPostalCode}
        billingProvince={billingProvince}
        billingConfirm={billingConfirm}
        setBillingConfirm={setBillingConfirm}
        setBillingProvince={setBillingProvince}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        billingResError={billingResError}
        setBillingLoadingState={setBillingLoadingState}
        billingLoadingState={billingLoadingState}
        form={form}
        getFieldDecorator={getFieldDecorator}
        isProvinceError={isBillingProvinceError}
        setIsProvinceError={setIsBillingProvinceError}
        isPostalCodeError={isBillingPostalCodeError}
        setIsPostalCodeError={setIsBillingPostalCodeError}
        isStreetAddressError={isBillingStreetAddressError}
        setIsStreetAddressError={setIsBillingStreetAddressError}
      />
      <RewardsAndFinish
        rewardAndFinishLabelTranslation={orderNowLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        setRewardsOptions={setRewardsOptions}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        agreeToSyscoRewards={agreeToSyscoRewards}
        agreeToSyscoTerms={agreeToSyscoTerms}
        rewardsOptions={rewardsOptions}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        clearOnBoardingFormData={clearOnBoardingFormData}
        onboardingRewardsModalVisible={onboardingRewardsModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        rewardOptionActionNo={rewardOptionActionNo}
        billingHeaderVisible={billingHeaderVisible}
        setAgreeToSyscoRewards={setAgreeToSyscoRewards}
        setAgreeToSyscoTerms={setAgreeToSyscoTerms}
        setTermsAndConditionsVisible={setTermsAndConditionsVisible}
        termsAndConditionsVisible={termsAndConditionsVisible}
        setSyscoRewardsTermsAndConditionsVisible={setSyscoRewardsTermsAndConditionsVisible}
        syscoRewardsTermsAndConditionsVisible={syscoRewardsTermsAndConditionsVisible}
        rewardsResError={rewardsResError}
        rewardsIsSuccess={rewardsIsSuccess}
        setRewardsLoadingState={setRewardsLoadingState}
        rewardsLoadingState={rewardsLoadingState}
        getFieldDecorator={getFieldDecorator}
        rewardsFetching={rewardsFetching}
        isRewardsOnboardingForm={isRewardsOnboardingForm}
        setIsRewardsOnboardingForm={setIsRewardsOnboardingForm}
        form={form}
        resOpcoId={resOpcoId}
        userId={userId}
        isHomePage={false}
      />
      <Complete
        completeLabelTranslation={orderNowLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        onboardingCompletedModalVisible={onboardingCompletedModalVisible}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        hasWorkedWithSyscoRep={hasWorkedWithSyscoRep}
        isSyscoRep={isSyscoRep}
        setIsSyscoRep={setIsSyscoRep}
        setCustomerType={setCustomerType}
      />
      <ShippingType
        shippingTypeLabelTranslation={orderNowLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        setIsMonday={setIsMonday}
        setIsTuesday={setIsTuesday}
        setIsWednesday={setIsWednesday}
        setIsThursday={setIsThursday}
        setIsFriday={setIsFriday}
        setSelectedNumberOfDays={setSelectedNumberOfDays}
        setSelectedDeliveryDays={setSelectedDeliveryDays}
        setIsAM={setIsAM}
        setIsPM={setIsPM}
        setIsNoPreference={setIsNoPreference}
        setmaxDeliveryDays={setmaxDeliveryDays}
        selectedNumberOfDays={selectedNumberOfDays}
        selectedDeliveryDays={selectedDeliveryDays}
        maxDeliveryDays={maxDeliveryDays}
        isMonday={isMonday}
        isTuesday={isTuesday}
        isWednesday={isWednesday}
        isThursday={isThursday}
        isFriday={isFriday}
        isPM={isPM}
        isAM={isAM}
        isNoPreference={isNoPreference}
        resDistance={resDistance}
        resOpcoName={resOpcoName}
        resOpcoAddress={resOpcoAddress}
        setIsPickUp={setIsPickUp}
        setIsdDelivery={setIsdDelivery}
        customerResUserId={customerResUserId}
        isSameBillingAddress={isSameBillingAddress}
        billingHeaderVisible={billingHeaderVisible}
        isDelivery={isDelivery}
        isPickUp={isPickUp}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        resOpcoId={resOpcoId}
        shippingTypeModalVisible={shippingTypeModalVisible}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        setOnboardingBillingModalVisible={setOnboardingBillingModalVisible}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        pickUpResError={pickUpResError}
        deliveryResError={deliveryResError}
        setShippinTypeLoadingState={setShippinTypeLoadingState}
        shippinTypeLoadingState={shippinTypeLoadingState}
        deliveryWindow={deliveryWindow}
        setDeliveryWindow={setDeliveryWindow}
        purchaseOption={purchaseOption}
        setPurchaseOption={setPurchaseOption}
      />
      <Confirmation
        closeLabelTranslation={orderNowLabelTranslation}
        closeConfirmationVisible={closeConfirmationVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        handleTerminateOnBoarding={handleTerminateOnBoarding}
      />
      <Timeout
        timeoutLabelTranslation={orderNowLabelTranslation}
        setTimeoutModalVisible={setTimeoutModalVisible}
        timeoutModalVisible={timeoutModalVisible}
        handleTerminateOnBoarding={handleTerminateOnBoarding}
        setCallModalVisible={setCallModalVisible}
      />
      <Call
        timeoutLabelTranslation={orderNowLabelTranslation}
        setCallModalVisible={setCallModalVisible}
        callModalVisible={callModalVisible}
        handleTerminateOnBoarding={handleTerminateOnBoarding}
      />
      <Redirect
        setRedirectModalVisible={setRedirectModalVisible}
        redirectModalVisible={redirectModalVisible}
        redirectLabelTranslation={orderNowLabelTranslation}
      />
      {labelFetching ? <AppLoader /> : null}
    </Layout>
  );
}

export default Form.create()(OrderNow);
