import React, { useState } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { Provider } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import store from './store/index';

import Home from './pages/Home';
import OrderNow from './pages/OrderNow';
import './assets/css/App.css';
import './assets/css/Home.css';
import { LANGUAGE } from './constants/appConstants';

function App() {
  const { REACT_APP_INTERCOM_APP_ID } = process.env;
  const languageParam = new URLSearchParams(window.location.search)?.get('lang')?.toUpperCase();
  const [language, setLanguage] = useState(
    Object.values(LANGUAGE).includes(languageParam) ? languageParam : LANGUAGE.ENGLISH
  );
  return (
    <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID} autoBoot>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/ordernow" render={(props) => <OrderNow {...props} lang={language} />} />
            <Route exact path="/home" render={(props) => <Home {...props} lang={language} />} />
            <Route exact path="*" render={(props) => <Home {...props} lang={language} />} />
          </Switch>
        </Router>
      </Provider>
    </IntercomProvider>
  );
}

export default App;
